

























import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import AuthModule from '@/store/modules/auth'
import { AuthRestore } from '@/store/types'

@Component({
  components: {
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class RestoreForm extends Mixins(NotifyMixin) {
  private isSent = false
  private form: AuthRestore = {
    email: '',
  }

  private mounted () {
    const focusInput = ((this.$refs.focus as Vue).$el as HTMLElement).querySelector('input')

    if (focusInput) {
      focusInput.focus()
    }
  }

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          AuthModule.restore(this.form)
            .then(() => {
              requestAnimationFrame(() => (form.reset()))
              this.isSent = true
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }
}
