








import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

import RestoreForm from '@/components/forms/auth/RestoreForm.vue'

@Component({
  components: { RestoreForm },
})
export default class Restore extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Восстановление пароля',
    }
  }
}
